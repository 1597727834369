@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

code {
  color: #e01a76;
}

.leaflet-container {
  height: 600px;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply bg-white md:bg-gray-100;
}

table {
  @apply font-sans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-lime {
  background-color: rgba(171, 255, 141, 0.49);
}
.lime {
  color: #55c22f;
}

.max-h-screen-80 {
  max-height: 80vh;
}

.DayPickerInput {
}
.DayPickerInput > input {
  border: 1.5px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 6px;
  background: #f4f4f4;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 2px;
  text-align: center;
  width: 100px;
  height: 26px;
  font-weight: bold;
}

.DayPickerInput-Overlay {
  position: absolute;
}

.DayPickerInput-Overlay-container
  > .DayPickerInput
  > .DayPickerInput-OverlayWrapper
  > .DayPickerInput-Overlay {
  left: -110px;
}

.portal-dropdown__container > .portal-dropdown {
  border: 1.5px solid #c2c2c2;
  text-align-last: center;
}
.portal-dropdown > select::-ms-expand {
  display: none;
}
